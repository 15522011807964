import { render, staticRenderFns } from "./checkInfo.vue?vue&type=template&id=bcd97acc&scoped=true&"
import script from "./checkInfo.vue?vue&type=script&lang=js&"
export * from "./checkInfo.vue?vue&type=script&lang=js&"
import style0 from "./checkInfo.vue?vue&type=style&index=0&id=bcd97acc&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcd97acc",
  null
  
)

export default component.exports