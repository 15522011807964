<template>
    <div class="page-factory-check">
        <img :class="{'icon-goods': true, 'xfm-img': ryType == 17}" :src="ossHost + imgUrl" alt="">
        <div :class="{'check-info-head': true, 'xfm-head': ryType == 17}">
            <div class="device-number">{{ uniqueId }}</div>
            <div class="device-type" v-if="ryType != 17">{{ deviceName || '溶氧检测仪' }}</div>
            <div class="device-type" v-else>溶氧仪可视基础版</div>
            <!-- <div :class="{ 'device-angle': true, 'error': !signalPass }" @click="refreshSignal">信号强度：{{ signal }}<img
                    :src="ossHost + 'img/reflash.png'" alt=""></div>
            <div class="switch-btn" @click="changeCheckType">{{ !isRyV2Tool ? '切换新版质检' : '返回老版质检' }}</div> -->
        </div>

        <div class="check-box">
            <div class="check-body" v-if="ryType != 17">
                <div class="check-title">质检列表： <div :class="{'refresh-btn': true, 'disabled': loadingData }" @click="refreshSignal">重新获取</div></div>
                <div class="content-item">
                    <div class="content-item-label">信号强度已超过18 <b v-if="signal">({{ signal }})</b></div>
                    <div class="content-item-value">
                        <Loading size="24" type="spinner" v-if="loadingData" :color="themeColor" />
                        <Icon name="close" v-if="!signalPass && !loadingData"/>
                        <Icon name="passed" v-if="signalPass && !loadingData"/>
                    </div>
                </div>

                <div class="content-item">
                    <div class="content-item-label">探头质检<b v-if="sensorPass">合格</b> <span v-else>不合格</span> <span v-if="sensorErrText">({{ sensorErrText
                    }})</span></div>
                    <div class="content-item-value">
                        <Loading size="24" type="spinner" v-if="loadingData" :color="themeColor" />
                        <Icon name="close" v-if="!sensorPass && !loadingData"/>
                        <Icon name="passed" v-if="sensorPass && !loadingData"/>
                    </div>
                </div>

                <div class="content-item">
                    <div class="content-item-label">{{ warningList[0].text }}</div>
                    <div class="content-item-value">
                        <Loading size="24" type="spinner" v-if="loadingData" :color="themeColor" />
                        <Icon name="close" v-if="!warningList[0].pass && !loadingData"/>
                        <Icon name="passed" v-if="warningList[0].pass && !loadingData"/>
                    </div>
                </div>

                <div class="content-item">
                    <div class="content-item-label">30分钟内主机正常充排气 <span v-if="adErrText">{{ adErrText }}</span> <span v-if="pumpStatus">(气泵已关闭)</span>
                        <el-button type="primary" size="mini" v-if="pumpStatus" @click="onclickUpdatePumpBtn"
                            :loading="updatePumpStatusLoading">打开气泵</el-button></div>
                    <div class="content-item-value">
                        <Loading size="24" type="spinner" v-if="loadingData" :color="themeColor" />
                        <Icon name="close" v-if="!motorPass && !loadingData"/>
                        <Icon name="passed" v-if="motorPass && !loadingData"/>
                    </div>
                </div>
                <!-- 通过气路自检 -->
                <div class="content-item" v-if="!notPumpCheck">
                    <div class="content-item-label">通过气路气路检测<el-button type="primary" size="mini" v-if="!pumpPass && !pumpLoad" @click="onClickShowStartCheckModalBtn"
                            :loading="pumpLoad">重检</el-button><span v-if="!pumpPass && !pumpLoad && pumpCheckErrorText" class="error-text">({{pumpCheckErrorText}})</span></div>
                    <div class="content-item-value">
                        <Loading size="24" type="spinner" v-if="pumpLoad" :color="themeColor" />
                        <Icon name="close" v-if="!pumpPass && !pumpLoad"/>
                        <Icon name="passed" v-if="pumpPass && !pumpLoad"/>
                    </div>
                </div>
                <div class="content-item disable" v-else>
                    <div class="content-item-label">通过气路气路检测</div>
                    <div class="content-item-value">无需检测</div>
                </div>
            </div>
            <div class="check-box" v-if="ryType != 17">
                <div class="check-body">
                    <div class="content-item">
                        <div class="content-item-label">不含压力开关,无需进行气路检测</div>
                        <div class="content-item-value">
                            <el-switch v-model="notPumpCheck" @click="notPumpCheck = !notPumpCheck" active-color="#209A56" inactive-color="#bbb"></el-switch>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 小方帽质检 -->
            <div class="check-body" v-if="ryType == 17">
                <div class="check-title">质检列表： <div :class="{'refresh-btn': true, 'disabled': loadingData }" @click="refreshSignal">重新获取</div></div>
                <div class="content-item">
                    <div class="content-item-label">信号强度已超过18 <b v-if="signal">({{ signal }})</b></div>
                    <div class="content-item-value">
                        <Loading size="24" type="spinner" v-if="loadingData" :color="themeColor" />
                        <Icon name="close" v-if="!signalPass && !loadingData"/>
                        <Icon name="passed" v-if="signalPass && !loadingData"/>
                    </div>
                </div>

                <div class="content-item">
                    <div class="content-item-label">探头空气饱和氧偏差小于10% </div>
                    <div class="content-item-value">
                        <Loading size="24" type="spinner" v-if="loadingData" :color="themeColor" />
                        <Icon name="close" v-if="!fullOxyPass && !loadingData"/>
                        <Icon name="passed" v-if="fullOxyPass && !loadingData"/>
                    </div>
                </div>

                <div class="content-item">
                    <div class="content-item-label">探头零氧小于0.3</div>
                    <div class="content-item-value">
                        <Loading size="24" type="spinner" v-if="loadingData" :color="themeColor" />
                        <Icon name="close" v-if="!zeroOxyPass && !loadingData"/>
                        <Icon name="passed" v-if="zeroOxyPass && !loadingData"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer" v-if="ryType != 17">
            <div v-if="notPumpCheck" @click="passAction"
                :class="{ 'btn': true, 'pass': signalPass && motorPass && !errorStatus && sensorPass }">质检通过
            </div>
            <div v-else @click="passAction" :class="{ 'btn': true, 'pass': signalPass && pumpPass && warningList[0].pass && motorPass && !errorStatus && sensorPass }">
                质检通过
            </div>
        </div>

        <div class="footer" v-else>
            <div @click="passAction" :class="{ 'btn': true, 'pass': signalPass && fullOxyPass && zeroOxyPass }">
                质检通过
            </div>
        </div>

        <!-- <Popup v-model="isShowRyModal" class="ry-modal" position="center" round>
            <div class="modal-body" v-if="!pumpCheckStatus">
                <div class="modal-title">气路自检</div>
                <div class="modal-tip">1.整个气路自检大致时间为1分钟左右；</div>
                <div class="modal-tip">2.自检会分别检查设备是否进水,气泵，排气阀和进气阀是否正常工作。</div>
                <div class="modal-btn" @click="onClickShowStartCheckModalBtn">开始自检</div>
            </div>

            <div class="modal-body text-center" v-else>
                <div class="modal-title">下发成功</div>
                <div class="modal-tip">设备即将开始自检，您现在可扫码其他设备进行质检。</div>
                <div class="modal-tip text-red">*结果将保留30分钟,请记得及时回来确认</div>
                <div class="modal-btn" @click="isShowRyModal = false">我知道了</div>
            </div>
        </Popup> -->
    </div>
</template>

<script>
import { Toast, Icon, Loading } from "vant";
export default {
    name: "CheckInfo",
    components: {
        Icon,
        Loading
    },
    data() {
        const { ossHost } = this.$config.base;
        // 是否需要切换至新版质检逻辑
        return {
            ossHost,
            signalPass: false,
            motorPass: false,
            uniqueId: "-",
            signal: "-",
            warningList: [
                // {text: '数据有效', pass: true},
                // {text: '设备角度正常，正常泵气', pass: true},
                { text: '正常读取探头数据', pass: false },
                // {text: '探头稳定', pass: true},
                // {text: '算法在规定时间内给出目标值', pass: true}
            ],
            errorStatus: true,
            imgUrl: 'img/goods-ryy.png',
            sensorPass: false,
            sensorErrText: '',
            deviceName: '',
            adErrText: '',
            // 气泵状态：0是开启，1是关闭
            pumpStatus: 0,
            updatePumpStatusLoading: false,
            // 气路自检状态：0未开始自检，1点击下发自检命令，2下发成功等待自检结果
            pumpCheckStatus: 0,
            pumpPass: false,
            pumpCheckErrorText: '',
            // 获取质检状态
            loadingData: false,
            // 气路自检loading状态
            pumpLoad: true,
            // 是否不支持气路检测
            notPumpCheck: false,
            themeColor: '#209A56',
            // 溶氧类型
            // 3,4 电解法溶氧仪、5-16 荧光法溶氧仪，17是小方帽
            ryType: 14,
            // 小方帽饱和氧合格状态
            fullOxyPass: false,
            // 小方帽零氧合格状态
            zeroOxyPass: false
        };
    },
    created() {
        const vm = this;
        vm.fullCount = 0;
        vm.$route.query.uuid
            ? vm.loadDeviceInfo()
            : vm.$router.replace({
                path: "/factory/index"
            });
    },
    destroyed() {
        // 清除定时器
        this.loadCheckIntervalId && clearInterval(this.loadCheckIntervalId)
        this.intervalId && clearInterval(this.intervalId);
    },
    methods: {
        passAction() {
            const vm = this;
            if (vm.ryType == 17) {
                if (!(vm.signalPass && vm.fullOxyPass && vm.zeroOxyPass)) {
                    return
                }
            } else {
                if (!(vm.signalPass && vm.motorPass && !vm.errorStatus && vm.sensorPass)) {
                    return;
                }

                if (!vm.notPumpCheck && !vm.pumpPass) {
                    return
                }
            }

            const { USER_NAME } = vm.$config.keys;
            const name = vm.$localStorage.getItem(USER_NAME);
            const tt = Toast.loading({
                message: "加载中...",
                forbidClick: true,
                overlay: true,
                duration: 0
            });
            vm.$http({
                method: "post",
                except: true,
                url: `${vm.$config.base.BASE_URL}admin/iotTool/product/device/${vm.uniqueId
                    }/pass`,
                data: {
                    name,
                    signal: vm.signal > 100 ? vm.signal - 100 : vm.signal,
                    v: 2
                }
            }).then(res => {
                const { code, data, message } = res;
                tt.clear();
                if (code == 1) {
                    Toast.success({
                        message: data || "已成功通过质检~",
                        forbidClick: true,
                        overlay: true,
                        duration: 3000,
                        onClose() {
                            vm.$router.replace({
                                path: "/factory/index"
                            });
                        }
                    });
                } else {
                    Toast.fail({
                        message,
                        forbidClick: true,
                        overlay: true,
                        duration: 3000,
                    });
                }
            })
                .catch(err => {
                    console.log(err);
                    tt.clear();
                    Toast.fail({
                        message: JSON.stringify(err),
                        forbidClick: true,
                        overlay: true,
                        duration: 3000,
                    });
                });
        },

        // 打开溶氧仪气泵
        onclickUpdatePumpBtn() {
            const vm = this
            const { ADMIN_URL, ADMIN_TOKEN } = vm.$config.base;
            vm.updatePumpStatusLoading = true
            vm.$http({
                type: "PUT",
                // url: `${ADMIN_URL}javaApi/iot/factoryRyAdSample/${deviceId}`,
                url: `${ADMIN_URL}javaApi/iot/devices/register/config/${vm.deviceId}`,
                headers: { 'access-token': ADMIN_TOKEN },
                except: true,
                data: {
                    stopPump: 0
                }
            }).then(res => {
                let { code, message } = res;
                vm.updatePumpStatusLoading = false
                if (code == 1) {
                    vm.pumpStatus = 0;
                    Toast.success({
                        message: '已成功开启气泵',
                        forbidClick: true,
                        overlay: true,
                        duration: 3000
                    });
                } else {
                    Toast.fail({
                        message,
                        forbidClick: true,
                        overlay: true,
                        duration: 3000
                    });
                    vm.loadDeviceConfig(vm.deviceId)
                }
            }).catch(err => {
                console.log(err)
            })
        },

        // 重新检测
        onClickShowStartCheckModalBtn() {
            const vm = this
            const { ADMIN_URL, ADMIN_TOKEN } = vm.$config.base;
            vm.pumpLoad = true
            vm.pumpCheckErrorText = ''
            vm.$http({
                type: "put",
                // url: `${ADMIN_URL}javaApi/iot/factoryRyAdSample/${deviceId}`,
                url: `${ADMIN_URL}javaApi/iot/ry/${vm.ryDeviceId}/originCommand`,
                headers: { 'access-token': ADMIN_TOKEN },
                except: true,
                data: {
                    airChanelCheckRequest: {
                        motorHz: 0,
                        motorRatio: 0
                    },
                    status: 1
                }
            }).then(res => {
                let { code, message } = res;
                // 自检指令下发成功
                if (code == 1) {
                    vm.startTime = new Date().getTime()
                    vm.loadCheckIntervalId = setInterval(vm.loadRyCheckStatus, 3000);
                } else {
                    Toast.fail({
                        message: message || '自检指令下发超时，请重试',
                        forbidClick: true,
                        overlay: true,
                        duration: 4000
                    });
                    vm.pumpLoad = false
                }
            })
        },

        // 获取气路自检结果列表
        loadRyCheckStatus() {
            const vm = this
            const { ADMIN_URL, ADMIN_TOKEN } = vm.$config.base;
            vm.$http({
                url: `${ADMIN_URL}javaApi/iot/ry/${vm.ryDeviceId}/airChannelCheckRecords`,
                headers: { 'access-token': ADMIN_TOKEN },
                except: true
            }).then(res => {
                let { code, data, message } = res;
                if (code == 1 && data.length) {
                    // 重新排序
                    const listArr = data.sort((a, b) => b.createAt - a.createAt)
                    // 有有效结果更改页面显示状态
                    if (Math.abs(new Date(listArr[0].createAt).getTime() - vm.startTime) < 3600 * 1000 * 2) {
                        const { inWater, inletValveWarn, outValveWarn, pumpWarn } = listArr[0];
                        clearInterval(vm.loadCheckIntervalId)
                        vm.ttid && vm.ttid.clear();
                        vm.pumpPass = !inWater && !inletValveWarn && !outValveWarn && !pumpWarn
                        if (!vm.pumpPass) {
                            const errArr = []
                            inWater && (errArr.push('进水'));
                            inletValveWarn && (errArr.push('充气阀异常'));
                            outValveWarn && (errArr.push('排气阀异常'));
                            pumpWarn && (errArr.push('气泵异常'))
                            errArr.length && (vm.pumpCheckErrorText = errArr.join(','))
                        }
                        vm.pumpLoad = false
                    } else {
                        // 三分钟还未获取到数据算超时
                        console.log(new Date().getTime() - vm.startTime)
                        if (new Date().getTime() - vm.startTime > 1000 * 180 || !vm.loadCheckIntervalId) {
                            clearInterval(vm.loadCheckIntervalId)
                            vm.ttid && vm.ttid.clear();
                            vm.loadCheckIntervalId && Toast.fail({
                                message: message || '自检指令下发超时，请重试',
                                forbidClick: true,
                                overlay: true,
                                duration: 4000
                            });
                            vm.pumpLoad = false
                        }
                    }
                } else {
                    // 三分钟还未获取到数据算超时
                    if (new Date().getTime() - vm.startTime > 1000 * 180 || !vm.loadCheckIntervalId) {
                        clearInterval(vm.loadCheckIntervalId)
                        vm.ttid && vm.ttid.clear();
                        vm.loadCheckIntervalId && Toast.fail({
                            message: message || '自检指令下发超时，请重试',
                            forbidClick: true,
                            overlay: true,
                            duration: 4000
                        });
                        vm.pumpLoad = false
                    }
                }
            }).catch(err => {
                vm.ttid && vm.ttid.clear();
                vm.pumpLoad = false
                Toast.fail({
                    message: err.message || '自检指令下发超时，请重试',
                    forbidClick: true,
                    overlay: true,
                    duration: 4000
                });
            })
        },

        // 获取设备配置
        loadDeviceConfig(deviceId) {
            const vm = this
            const { ADMIN_URL, ADMIN_TOKEN } = vm.$config.base;
            vm.$http({
                type: "get",
                // url: `${ADMIN_URL}javaApi/iot/factoryRyAdSample/${deviceId}`,
                url: `${ADMIN_URL}javaApi/iot/devices/register/config/${deviceId}`,
                headers: { 'access-token': ADMIN_TOKEN },
                except: true
            }).then(res => {
                let { code, data, message } = res;
                vm.ttid && vm.ttid.clear();
                vm.ttid = "";
                if (code == 1) {
                    vm.ryDeviceId = data.deviceId
                    if (data && data.configFields) {
                        vm.pumpStatus = data.configFields[22].value
                    }
                    // 如果新版质检流程刚进来的时候获取该设备的质检结果
                    vm.startTime = new Date().getTime();
                    vm.loadRyCheckStatus()
                } else {
                    Toast.loading({
                        message,
                        forbidClick: true,
                        overlay: true,
                        duration: 3000
                    });
                }
            })
        },

        loadAdLine(deviceId) {
            const vm = this
            const { ADMIN_URL, ADMIN_TOKEN, oxygenValList } = vm.$config.base;
            const errObj = {
                "ERR_NONE": "正常",
                "ERR_PIPE_LEAKAGE": "管道漏气",
                "ERR_NOT_CLASSIFIED": "未分类",
                "ERR_SENSOR_BROKEN": "探头严重破损",
                "ERR_SENSOR_POLLUTED": "探头污染",
                "ERR_MINOR_LEAKAGE": "探头轻微破损",
                "ERR_UNKNOWN_ERROR": "未知类型异常",
                "ERR_FLAT_IN_AIR": "探头未接触水面",
                "ERR_FLAT_IN_WATER": "探头泡水"
            }
            // const year = new Date().getUTCFullYear();
            // const month = new Date().getMonth() + 1 > 9 ? new Date().getMonth() + 1 : `0${new Date().getMonth() + 1}`;
            // const days = new Date().getDate() > 9 ? new Date().getDate() : `0${new Date().getDate()}`;
            const year = new Date().getFullYear();
            const month = new Date().getMonth() + 1 > 9 ? new Date().getMonth() + 1 : `0${new Date().getMonth() + 1}`;
            const date = new Date().getDate() > 9 ? new Date().getDate() : `0${new Date().getDate()}`;
            vm.$http({
                type: "get",
                // url: `${ADMIN_URL}javaApi/iot/factoryRyAdSample/${deviceId}`,
                url: `${ADMIN_URL}javaApi/iot/ry/${deviceId}/adSampleList/${year}${month}${date}`,
                headers: { 'access-token': ADMIN_TOKEN },
                except: true
            }).then(res => {
                let { code, data, message } = res;
                vm.ttid && vm.ttid.clear();
                vm.ttid = "";
                if (code == 1) {
                    // data = [{"_id":"62a6fd1fa0b7024203d541ef","device_id":"1104589390","data_index":1,"oxygen":0.0,"last_oxygen":7.4,"voltage":0.1,"last_voltage":74.6,"temperature":0,"last_temperature":295,"start_time":1010,"test_time":72,"spacing_time":5,"type":2,"data_count":132,"data_group":"[748, 748, 731, 714, 617, 523, 436, 356, 319, 283, 219, 163, 86, 50, 15, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 9, 70, 146, 172, 199, 258, 325, 401, 443, 485, 577, 677, 699, 705, 711, 719, 725, 731, 734, 735, 737, 740, 741, 743, 743, 743, 744, 745, 745, 745, 745, 745, 745, 746, 746, 746, 747, 746, 746, 746, 746, 747, 747, 747, 747, 746, 746, 746, 746, 746, 746, 746, 746, 746, 746, 746, 746, 746]","create_time":"2022-06-13 17:02:23","errorCode":"ERR_NONE","sensorId":"22060384","device_error":"ERR_NONE","measured_o2":0.0,"measured_ad":1,"final_o2":0.0,"saturated_AD":749,"zero_AD":0,"calib_temperature":29.0,"sensor_broken":0,"sensor_broken_ad":0,"calibType":12}]
                    if (data && data.length) {
                        let sensorErrText = ''
                        const arr = data.sort((a, b) => new Date(b.create_time.split(' ').join('T')).getTime() - new Date(a.create_time.split(' ').join('T')).getTime())
                        const adList = JSON.parse(arr[0].data_group) || [];
                        let fullOxy = 0
                        const tmp = parseInt((arr[0].temperature || arr[0].last_temperature) / 10)
                        // 零氧点取6分钟的点，低于0.2
                        const zeroOxy = adList[72]
                        if (arr[0] && new Date().getTime() - new Date(arr[0].create_time.split(' ').join('T')).getTime() < 3000 * 1000) {
                            if (arr[0] && ['ERR_NONE', 'ERR_SENSOR_POLLUTED'].includes(arr[0].errorCode)) {
                                vm.motorPass = true
                            } else {
                                arr[0] && (vm.adErrText = errObj[arr[0].errorCode])
                            }

                            // 饱和氧用当前AD曲线前后点的最大值
                            if (adList[0] || adList[adList.length - 1]) {
                                fullOxy = adList[adList.length - 1] > adList[0] ? adList[adList.length - 1] : adList[0];
                            }

                            // 探头饱和氧偏差值
                            const fullOxyVg = (fullOxy / 100 - oxygenValList[tmp]) / oxygenValList[tmp]

                            // 饱和氧取点，第一个、最后一个或者后台给的满足条件就行
                            vm.sensorPass = fullOxyVg <= 0.1 && fullOxyVg > -0.3 && zeroOxy <= 20
                            console.log(fullOxyVg)
                            console.log(zeroOxy)
                            if (!vm.sensorPass) {
                                !(fullOxyVg <= 0.1 && fullOxyVg > -0.3) && (sensorErrText = '饱和氧偏差过大')
                                zeroOxy > 50 && (sensorErrText = sensorErrText ? '饱和氧跟收敛时间都不合格' : '收敛时间不合格')
                                vm.sensorErrText = sensorErrText
                            }
                        }
                    }
                } else {
                    Toast.loading({
                        message,
                        forbidClick: true,
                        overlay: true,
                        duration: 3000
                    });
                }
            })
                .catch(err => {
                    console.log(err);
                    vm.ttid && vm.ttid.clear();
                    vm.ttid = "";
                });
        },

        loadDeviceInfo() {
            const vm = this;
            vm.loadingData = true
            // vm.ttid = Toast.loading({
            //     message: "加载中...",
            //     forbidClick: true,
            //     overlay: true,
            //     duration: 0
            // });
            vm.$http({
                type: "get",
                url: `${vm.$config.base.BASE_URL}admin/iotTool/product/device/${vm.$route.query.uuid
                    }`,
                except: true
            })
                .then(res => {
                    const { code, data, message } = res;
                    if (code == 1) {
                        if (!data.uniqueId) {
                            Toast.fail({
                                message: "二维码错误，设备不存在~",
                                forbidClick: true,
                                overlay: true,
                                duration: 3000,
                                onClose() {
                                    vm.$router.replace({
                                        path: "/factory/index"
                                    });
                                }
                            });
                        }
                        vm.uniqueId = data.uniqueId;
                        vm.deviceId = data.deviceId;
                        vm.signal = data.list[0] ? data.list[0].signal : 0;
                        vm.signal = vm.signal > 100 ? vm.signal - 100 : vm.signal;
                        vm.signalPass = vm.signal > 100 ? vm.signal >= 119 : vm.signal >= 19;
                        vm.deviceName = vm.$strTool.filterRyyDeviceName(data.type)
                        vm.ryType = data.type;
                        // 小方帽出厂测试流程
                        // 只测饱和氧偏差+零氧<0.3+信号
                        if (data.type == 17) {
                            vm.imgUrl = 'img/ry-xfm.png';
                            vm.loadXfmOxyData();
                            !vm.intervalId && (vm.intervalId = setInterval(vm.loadXfmOxyData, 10000));
                        } else {
                            // 小红帽出厂测量流程
                            vm.loadAdLine(data.deviceId);
                            vm.loadDeviceConfig(data.deviceId);
                            data.list.sort((a, b) => new Date(b.createTime).getTime() - new Date(a.createTime).getTime())
                            if (data.type > 4) {
                                vm.imgUrl = 'img/goods-ygf-ryy.png'
                            }
                            data.list.length && vm.checkErrorCode(data.list[0].errorCode);
                        }
                    } else {
                        vm.ttid && vm.ttid.clear();
                        vm.ttid = "";
                        Toast.loading({
                            message,
                            forbidClick: true,
                            overlay: true,
                            duration: 3000
                        });
                    }
                    vm.loadingData = false
                })
                .catch(err => {
                    console.log(err);
                    vm.ttid && vm.ttid.clear();
                    vm.ttid = "";
                    vm.loadingData = false
                });
        },

        // code： 0表示正常，1表示有异常
        checkErrorCode(code) {
            const vm = this;
            // const codeStr = vm.$strTool.dec2bin(code, 8);
            // `${codeStr}`.split('').map((c, index) => {
            //     if (index == 2) {
            //         c == '1' && (vm.errorStatus = true)
            //         c == '1' && (vm.warningList[0].pass = false);
            //     }
            // })
            if (Number(code) == 0) {
                vm.errorStatus = false
                vm.warningList[0].pass = true
            }
        },

        refreshSignal() {
            const vm = this;
            vm.loadDeviceInfo();
        },
        // 主动刷新小方帽数据
        loadXfmOxyData() {
            const vm = this;
            const { ADMIN_URL, ADMIN_TOKEN } = vm.$config.base;
            vm.$http({
                type: "get",
                // url: `${ADMIN_URL}javaApi/iot/factoryRyAdSample/${deviceId}`,
                url: `${ADMIN_URL}javaApi/iot/refreshStatus/${vm.deviceId}`,
                headers: { 'access-token': ADMIN_TOKEN },
                except: true
            }).then(res => {
                console.log(res);
                vm.loadXfmUpdateLog();
            }).catch(err => {
                console.log(err);
            })
        },

        // 获取小方帽上报的日志
        loadXfmUpdateLog() {
            const vm = this;
            const { ADMIN_URL, ADMIN_TOKEN, oxygenValList } = vm.$config.base;
            vm.$http({
                type: "get",
                // url: `${ADMIN_URL}javaApi/iot/factoryRyAdSample/${deviceId}`,
                url: `${ADMIN_URL}javaApi/iot/logs/${vm.deviceId}/v2?start=${new Date().getTime() - 1200000}&end=${new Date().getTime()}&pageNo=1&type=2`,
                headers: { 'access-token': ADMIN_TOKEN },
                except: true
            }).then(res => {
                res.data.length && res.data.map(item => {
                    // 上报数据check
                    if (item.log.includes('数据索引:')) {
                        const tmp = parseInt(Number(item.log.split('测量温度')[1].split(',')[0]));
                        const fullOxy = Number(item.log.split('水中电压')[1].split(',')[0]);
                        const signal = Number(item.log.split('信号强度')[1].split(',')[0]);
                        const readSucc = item.log.includes('正常读取');
                        // 正常读取数据的情况下判断饱和氧+零氧
                        if (readSucc) {
                            // 探头饱和氧偏差值，至少5个溶氧点才能满足
                            const fullOxyVg = (fullOxy / 100 - oxygenValList[tmp]) / oxygenValList[parseInt(tmp)]
                            if (fullOxyVg <= 0.1 && fullOxyVg > -0.3) {
                                vm.fullCount++;
                                if (vm.fullCount >= 3) {
                                    vm.fullOxyPass = true
                                }
                            }

                            vm.signal = signal;
                            vm.signalPass = signal - 100 >= 18;
                            if (fullOxy <= 20 && fullOxy) {
                                vm.zeroOxyPass = true;
                            }
                        }
                    }
                })

                if (vm.fullOxyPass && vm.zeroOxyPass) {
                    vm.loadingData = false;
                    clearInterval(vm.intervalId);
                }
            }).catch(err => {
                console.log(err);
            })
        }
    }
};
</script>

<style lang="less" scoped>
@import "@/less/base.less";

.page-factory-check {
    width: 100%;
    height: 100%;
    background: @blank;
    position: relative;
    overflow-y: auto;
    color: @black;

    .icon-goods {
        position: absolute;
        width: 1.2rem;
        display: block;
        left: -0.2rem;
        top: 0.2rem;
        &.xfm-img {
            left: 0;
            top: 0;
        }
    }

    .check-info-head {
        padding: 0.45rem 0 0.25rem 1rem;
        font-size: 0.2rem;
        &.xfm-head {
            padding-left: 1.3rem;
        }

        .switch-btn {
            background: @themeColor;
            color: @blank;
            padding: 5px 0;
            border-radius: 4px;
            width: 150px;
            text-align: center;
            font-size: 0.16rem;
        }

        .device-number {
            font-size: 0.4rem;
            font-weight: 600;
        }

        .device-type {
            margin-bottom: 0.15rem;
        }

        .device-angle {
            font-weight: 600;

            &.error {
                color: @textColor2;
            }

            img {
                width: 0.2rem;
                display: inline-block;
                position: relative;
                top: 2px;
            }
        }
    }

    .check-box {
        padding: 0 0.12rem;
        position: relative;
        z-index: 1;
        margin-bottom: 20px;
        .check-body {
            box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.1);
            padding: 0.12rem 0.1rem 0.2rem;
            border-radius: 0.05rem;
            color: @black;
            background: @blank;
            .check-title {
                font-size: 0.2rem;
                display: flex;
                justify-content: space-between;
                .disabled {
                    color: @textColor13;
                }
            }

            // .check-item {
            //     padding-top: 0.2rem;
            //     padding-right: 0.2rem;
            //     position: relative;
            //     font-size: 0.17rem;

            //     &.pump-item {
            //         button {
            //             position: absolute;
            //             right: 0;
            //             background: @themeColor;
            //             border-color: @themeColor;

            //             &.el-button--text {
            //                 background: @blank;
            //                 border-color: @blank;
            //                 color: @themeColor;
            //             }
            //         }

            //         &:after {
            //             display: none;
            //         }
            //     }

            //     span {
            //         color: red;
            //     }

            //     b {
            //         font-weight: normal;
            //     }

            //     &.disabled {
            //         color: fade(@textColor1, 50);

            //         &:after {
            //             border-color: @textColor1;
            //         }

            //         img {
            //             display: none;
            //         }
            //     }

            //     img {
            //         position: absolute;
            //         display: block;
            //         width: 0.16rem;
            //         right: -0.03rem;
            //         top: 0.24rem;
            //     }

            //     &:after {
            //         content: " ";
            //         display: block;
            //         width: 0.12rem;
            //         height: 0.12rem;
            //         border-radius: 0.06rem;
            //         border: 1px solid @black;
            //         position: absolute;
            //         right: 0;
            //         top: 0.26rem;
            //         box-sizing: border-box;
            //     }
            // }
            .content-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                min-height: 70px;
                &.disable {
                    color: @textColor13;
                }
                &-value {
                    display: flex;
                    align-items: center;
                    width: 40%;
                    word-break: break-all;
                    text-align: right;
                    justify-content: flex-end;
                    .disable {
                        color: @textColor13;
                    }
                    .van-icon {
                        font-size: 0.26rem;
                        margin-right: 5px;
                    }
                    .van-loading {
                        margin-right: 5px;
                    }
                    .van-icon-close,
                    .error {
                        color: @textColor2;
                    }
                    .van-icon-passed {
                        color: @themeColor;
                    }
                    .van-button {

                    }
                    .check-item {
                        width: 0.24rem;
                        height: 0.24rem;
                        border-radius: 50%;
                        border: 1px solid @textColor1;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &.on {
                            border-color: @themeColor;
                            background: @themeColor;
                            &:after {
                                display: block;
                                content: ' ';
                                position: absolute;
                                width: 10px;
                                height: 4px;
                                border-bottom: 2px solid @blank;
                                border-left: 2px solid @blank;
                                transform: rotate(-45deg);
                            }
                        }
                    }
                }
                &-label {
                    width: 60%;
                    word-break: break-all;
                    font-size: 0.16rem;
                    span {
                        color: red;
                    }
                    .el-button {
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    .footer {
        margin-top: 0.25rem;

        .btn {
            width: 2.35rem;
            height: 0.6rem;
            line-height: 0.6rem;
            color: @blank;
            border-radius: 0.3rem;
            background: @bgColor1;
            text-align: center;
            font-size: 0.2rem;
            box-shadow: 0px 2px 10px 0px @bgColor1;
            margin: 0 auto 1rem;

            &.pass {
                background: @themeColor;
            }
        }
    }

    .ry-modal {
        width: 80%;
        padding: 20px;
        font-size: 0.18rem;
        line-height: 1.6;

        .text-center {
            text-align: center;
        }

        .text-red {
            color: @textColor2;
        }

        .modal-title {
            font-weight: bold;
            font-size: 0.2rem;
            text-align: center;
        }

        .modal-tip {
            padding-top: 0.15rem;
        }

        .modal-btn {
            line-height: 0.5rem;
            border-radius: 0.25rem;
            text-align: center;
            background: @themeColor;
            color: @blank;
            letter-spacing: 1px;
            margin-top: 30px;
        }
    }
}
</style>
